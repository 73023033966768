/*
|--------------------
|      HEADER
|--------------------
*/

/*
|
| Header
|---------
*/
.link-menu {
  color: #ffffff;
  font-family: "Inter";
  font-size: 16px;
  line-height: 20px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
}

#header {
  width: 100%;
  position: absolute;
  z-index: 10;

  &.active {
    position: fixed;
    background: #fff;
    .item-link {
      color: #4b0e13 !important;
    }
    .item-menu {
      padding: 25px 0 15px 0 !important;
      border: none !important;
      .menu-item-has-children:before {
        content: "";
        background-image: url(../img/icon/bottom-r.svg) !important;
      }
    }
    .nav-drop {
      top: 62px !important;
    }
  }

  .header-container {
    @extend .container;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .logo-head {
      a {
        margin-top: 0px;
        transition: all ease 0.8s;

        img {
          max-height: 149px;
        }
      }

      .item-logo {
        svg {
          path,
          polygon {
            fill: $black;
          }
        }
      }
    }

    .item-nav {
      display: none;

      .item-menu {
        display: flex;

        li {
          &:not(:last-child) {
            margin-right: 105px;
            @include media-breakpoint-down(xl) {
              margin-right: 50px;
            }
          }

          .item-link {
            @extend .link-menu;
          }
        }
      }

      .menu-item {
        .nav-drop {
          position: absolute;
          background: #fff;
          width: 400px;
          padding: 25px;
          top: 71px;
          max-height: 0;
          display: block;
          overflow: hidden;
          opacity: 0;
          -webkit-transition: all 0.8s;
          -moz-transition: all 0.8s;
          -ms-transition: all 0.8s;
          -o-transition: all 0.8s;
          transition: all 0.8s;

          &:before {
            content: "";
            width: 100%;
            height: 5px;
            background-color: #4b0e13;
            display: block;
            position: absolute;
            top: 0;
            left: 0;
          }

          li {
            margin: initial;

            &:not(:last-child) {
              padding-bottom: 10px;
            }

            a {
              color: #444444;
              font-family: "Inter";
              font-size: 16px;
              font-weight: 300;
              font-style: normal;
              letter-spacing: normal;
              line-height: 22px;
              text-align: left;

              &:hover {
                color: #4b0e13;
                font-weight: bold;
              }
            }
          }
        }

        &:hover {
          .nav-drop {
            max-height: 300px;
            opacity: 1;
          }
        }
      }

      @include media-breakpoint-up(xl) {
        display: flex;
        align-items: center;

        .item-menu {
          padding: 0 0 28px 0;
          border-bottom: 1px solid #dfd1ca;

          .menu-item {
            &.menu-item-has-children {
              position: relative;

              &:before {
                content: "";
                background-image: url("../img/icon/bottom-w.svg");
                background-size: cover;
                width: 13px;
                height: 7px;
                display: block;
                position: absolute;
                right: -28px;
                top: calc(50% - 4px);
              }
            }
          }
        }
      }
    }
  }

  .lang-container {
    position: relative;
    display: inline-flex;
    top: 1px;
    @extend .link-menu;
    cursor: pointer;

    ul {
      position: absolute;
      z-index: 1;
      top: 100%;
      left: 0;
      opacity: 0;
      visibility: hidden;
      transform: translateX(10px);
      transition: opacity 1.2s $easeSmooth, transform 2s $easeSmooth;
    }

    &:hover {
      ul {
        opacity: 1 !important;
        visibility: visible !important;
        transform: translateX(0) !important;
      }
    }
  }

  .btn-menu {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    //width: 56px;
    //height: 56px;
    margin-top: 5px;
    padding: 0;
    border: none;
    background-color: transparent;
    cursor: pointer;

    & > .item-burger {
      display: block;
      width: 25px;

      & > span {
        display: block;
        width: 100%;
        height: 2px;
        background-color: #e6e6e6;
        border-radius: 2px;

        &:nth-child(2) {
          margin: 6px 0;
        }
      }
    }

    @include media-breakpoint-up(xl) {
      display: none;
    }
  }

  &.active {
    .logo-head {
      a {
        margin-top: -66px;
        transition: all ease 0.8s;
      }
    }
    &.mob-active {
      position: absolute !important;
    }
  }
}

/*
|
| Menu
|-------
*/
#mobile-menu {
  position: fixed;
  z-index: 9;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100vh;
  background-color: $white;
  overflow: auto;
  opacity: 0;
  visibility: hidden;

  .item-container {
    @extend .container;
    height: inherit;

    & > ul {
      padding-top: 170px;
    }

    ul {
      & > li {
        margin-left: 20px;

        a {
          text-transform: uppercase;
          color: $very-dark-grey;
          font-size: 16px;
          font-weight: 500;
          -webkit-font-smoothing: antialiased;
        }

        .nav-drop {
          li {
            a {
              font-size: 15px;
              padding-bottom: 5px;
              text-transform: initial;
              font-weight: 400;
            }
          }
        }

        br {
          display: none;
        }
      }
    }

    .menu-item {
      margin-bottom: 10px;
    }
  }

  .item-socials-container {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;

    .item-socials {
      display: flex;
      align-items: center;
      margin-bottom: 25px;

      a {
        font-size: 25px;

        &:not(:last-child) {
          margin-right: 25px;
        }
      }

      @include media-breakpoint-down(xs) {
        margin-bottom: 15px;
        a {
          font-size: 20px;
        }
      }
    }
  }
}

.intro-block {
  padding: 60px 100px;
  @include media-breakpoint-down(sm) {
    padding: 25px 10px;
  }

  .title {
    color: #e2723f;
    font-family: "Acumin Pro Cond";
    font-size: 38px;
    line-height: initial;
    font-weight: 700;
    text-align: left;
    position: relative;
    padding-bottom: 10px;

    &:before {
      content: "";
      background-image: url("../img/icon/arrow-b.svg");
      height: 75px;
      width: 22px;
      display: block;
      position: absolute;
      left: -37px;
      top: 37px;
      @include media-breakpoint-down(sm) {
        content: none;
      }
    }
  }

  .text {
    color: #444444;
    font-family: "Inter";
    font-size: 16px;
    font-weight: 300;
  }

  p {
    &:not(:last-child) {
      padding-bottom: 10px;
    }
  }
}
