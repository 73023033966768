/*
|--------------------
|      CONTENT
|--------------------
*/

/*
|
| Banner
|---------
*/
.banner {
  @extend .bg-cover;
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 500px;
  background-color: $black;
  left: 10%;
  @include media-breakpoint-down(md) {
    left: 0;
  }

  &:after {
    content: "";
    background-color: #000;
    opacity: 0.3;
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    height: auto;
    z-index: 1;
  }

  &:before {
    content: "";
    background-image: url("../img/global/degrade.png");
    height: 244px;
    width: 100%;
    background-repeat: repeat-x;
    position: absolute;
    top: 0;
  }

  .item-content {
    width: 100%;
    padding: 200px 0 0 0;
  }

  .block-head {
    margin-left: 10%;
    @include media-breakpoint-down(md) {
      margin: auto;
    }
  }
}

/*
|
| Custom card
|
| Use in news archive page
|--------------
*/
.custom-card {
  .custom-card-link {
    display: block;
  }

  /*
  * Card img
  */
  .card-img-container {
    position: relative;
    overflow: hidden;

    &:after {
      content: "";
      display: block;
      padding-bottom: 100%;
    }

    .card-img {
      position: absolute;

      top: 0;
      left: 0;

      width: 100%;
      height: 100%;

      transition: transform 2s $easeOutExpo;
      will-change: transform;
    }
  }

  /*
  * Card content
  */
  .card-content {
    .date {
      color: rgba($very-dark-grey, 0.6);
    }
  }

  /*
  * Hover state
  */
  &:hover {
    .card-img-container {
      .card-img {
        transform: scale(1.05);
      }
    }
  }
}

/*
|
| Cookie banner
|----------------
*/
.cookie-banner {
  display: none !important;
  position: fixed;
  z-index: 1;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 30px 0;

  .item-close {
    font-size: 24px;
    cursor: pointer;
  }

  @include media-breakpoint-down(sm) {
    padding: 20px 0;
    font-size: 12px;
  }

  @include media-breakpoint-down(xs) {
    padding: 10px 0 20px 0;

    .item-close {
      font-size: 18px;
    }
  }
}

/*
|
| Pagination
|---------------
*/
.pagination-container {
  li {
    display: inline-block;
    font-family: "Inter";
    font-size: 16px;

    a {
      transition: all 0.3s ease-out;
      padding: 0 8px;
      font-weight: 600;

      &.prev {
        transform: scaleX(-1);
      }

      &:hover {
        color: #e2723f;
      }
    }

    .current {
      padding: 0 8px;
      color: #e2723f;
      font-weight: 600;
    }
  }
}

/*
|
| CMS
|------
*/
.cms {
  padding: 50px 0;
  background-color: #fff;

  .flexible {
    span {
      display: inline;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin-bottom: 30px;
      @include media-breakpoint-down(sm) {
        margin-left: initial;
      }
    }

    h1 {
      @extend .title-xxl;
    }

    h2 {
      color: $white;
      display: table;
      padding: 7px 15px 3px 15px !important;
      font-family: "Acumin Pro Cond";
      font-size: 28px;
      font-weight: 700;
      font-style: normal;
      letter-spacing: normal;
      line-height: 30px;
      text-align: left;
      position: relative;
      z-index: 1;

      &:before {
        content: "";
        display: block;
        position: absolute;
        background-color: $orange;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: -1;
      }
    }

    h3 {
      display: inline-block;
      padding: 7px 15px 3px 45px !important;
      font-family: "Inter";
      font-size: 22px;
      font-weight: 400;
      text-transform: uppercase;
      font-style: normal;
      letter-spacing: normal;
      line-height: 26px;
      text-align: left;
      @include media-breakpoint-down(md) {
        padding: 7px 0px 3px 0px !important;
      }
    }

    h4 {
      @extend .title-md;
    }

    h5 {
      @extend .title-sm;
    }

    h6 {
      @extend .title-xs;
    }

    img {
      display: block;
      max-width: 100%;
      height: auto;
      margin-top: 60px;
      margin-bottom: 60px;
      @include media-breakpoint-down(md) {
        margin-top: 20px;
        margin-bottom: 30px;
      }
    }

    strong {
      font-weight: 800;
    }

    a {
      color: #e2723f;
      font-weight: 700;
      font-family: "Inter";
      transition: all ease 0.3s;

      &:hover {
        opacity: 0.5;
        transition: all ease 0.3s;
      }
    }

    p {
      color: #444444;
      font-family: "Inter";
      font-size: 16px;
      font-weight: 300;
      margin-bottom: 20px;
      margin-left: 150px;
      @include media-breakpoint-down(md) {
        margin-left: 0px;
      }
    }

    &.cms-no-break {
      p {
        margin-bottom: 0;
      }
    }

    ul {
      margin-left: 220px;
      margin-bottom: 20px;
      @include media-breakpoint-down(md) {
        margin-left: 0px;
      }

      li {
        color: #444444;
        font-family: "Inter";
        font-size: 16px;
        font-weight: 300;
        list-style: initial;
      }
    }

    blockquote {
      position: relative;

      width: 80%;

      margin-right: 0;
      margin-left: auto;
      padding: 130px 0;

      font-size: 30px;
      line-height: 1.5em;
      font-weight: 300;

      &:before {
        content: "";

        position: absolute;

        background-image: url("../img/icon/quote-left.svg");

        width: 50px;
        height: 50px;

        background-repeat: no-repeat;
        background-size: contain;
        background-position: center center;

        transform: translateX(-60px) translateY(-30px);
      }

      &:after {
        content: "";

        position: absolute;
        right: 0;

        background-image: url("../img/icon/quote-right.svg");

        width: 50px;
        height: 50px;

        background-repeat: no-repeat;
        background-size: contain;
        background-position: center center;

        transform: translateX(0) translateY(-50px);

        @include media-breakpoint-down(md) {
          transform: translateX(60px) translateY(-50px);
        }
      }
    }
  }
}

/*
|
| Page offset
|--------------
*/
//.page-offset {
//    padding-top: $headerHeight;
//
//    @include media-breakpoint-down(lg) {
//        padding-top: $headerHeightSm;
//    }
//}

/*
|
| Custom Loader
|---------------
*/
.custom-loader {
  width: 100px;
  display: inline-block;

  &.cl-center {
    margin-left: auto;
    margin-right: auto;
  }

  svg {
    display: inline-block;
    width: 100%;
    height: auto;
    fill: $black;

    circle {
      stroke: $black;
    }
  }

  &.ajax-loader {
    display: none;
  }
}

.videoyt {
  width: 100%;

  .plyr {
    .item-poster {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      z-index: 2;
      opacity: 1;
      transition: all ease 0.4s;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }
  }

  .plyr__control--overlaid {
    background-image: linear-gradient(90deg, #e2723f 0%, #e2723f 49%, #e2723f 100%);
    padding: 30px;
    @include media-breakpoint-down(md) {
      padding: 20px;
    }

    svg {
      color: #e2723f !important;
      height: 18px;
      width: 18px;
    }
  }

  .plyr__control--overlaid:hover {
    background-image: linear-gradient(90deg, #e2723f 0%, #e2723f 49%, #e2723f 100%);

    svg {
      color: white !important;
    }
  }

  .plyr--video {
    background: transparent !important;
  }

  .plyr__video-wrapper {
    background: transparent !important;
  }

  .plyr--full-ui input[type="range"] {
    color: #e2723f;
  }

  .plyr__video-embed iframe {
    top: -50%;
    height: 200%;
    width: 101%;
  }

  .plyr__poster {
    background-size: cover;
  }

  .plyr__control--overlaid {
    background: white;
  }

  .plyr--video .plyr__control.plyr__tab-focus,
  .plyr--video .plyr__control:hover,
  .plyr--video .plyr__control[aria-expanded="true"] {
    background: #e2723f;
  }

  .plyr__control.plyr__tab-focus {
    box-shadow: 0 0 0 5px rgba(#e2723f, 0.5);
  }

  .plyr__menu__container .plyr__control[role="menuitemradio"][aria-checked="true"]::before {
    background: #e2723f;
  }

  iframe{
    width: 100%;
    height: auto;
    aspect-ratio: 16 / 9;
    display: block;
  }
}


.btn {
  padding: 13px 30px 8px 30px;
  color: #e2723f;
  background-color: #fff;
  font-family: "Acumin Pro Cond";
  font-size: 20px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 24px;
  text-align: center;
  border-radius: 50px;
  border: none;

  &:hover {
    background-color: #4b0e13;
    color: #ffffff !important;
    opacity: 1 !important;
  }

  &.btn-arrow {
    height: 31px;
    width: 31px;
    background-image: url("../img/icon/arrow-left.svg");
    background-repeat: no-repeat;
    background-position: center;
    padding: 28px;
    transition: all ease 0.5s;
    border: 1px solid #efefef;

    &:hover {
      background-image: url("../img/icon/arrow-left-w.svg");
      background-position: 17px center;
      transition: all ease 0.5s;
      border: 1px solid #4b0e14;
    }
  }
}

.section_download {
  background-color: #f3ebe0;
  padding: 60px 40px;
  position: relative;

  &:before {
    content: "";
    background-image: url("../img/icon/hand.png");
    height: 52px;
    width: 99px;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
  }

  .ddl {
    .bloc-ddl {
      @include media-breakpoint-down(md) {
        display: block;
      }

      .desc-ddl {
        img {
          float: left;
          margin: initial;
          margin-top: 4px;
          max-width: 80px;
          width: 20%;
          @include media-breakpoint-down(md) {
            width: initial;
            float: initial;
            margin: 0 auto 20px auto;
          }
        }

        .block-ddl {
          float: left;
          width: 80%;
          padding-left: 15px;
          @include media-breakpoint-down(md) {
            width: 100%;
            float: initial;
            padding-left: initial;
            margin: auto;
          }

          h3 {
            color: #e2723f;
            font-family: "Acumin Pro Cond";
            font-size: 28px;
            font-weight: 700;
            line-height: 34px;
            text-align: left;
            margin: initial;
            @include media-breakpoint-down(md) {
              text-align: center;
            }
          }

          .content-ddl {
            color: #444444;
            font-family: "Inter";
            font-size: 18px;
            font-weight: 300;
            line-height: 24px;
            text-align: left;
            @include media-breakpoint-down(md) {
              text-align: center;
            }

            p {
              margin: initial;
            }
          }
        }
      }
    }

    .block-btn {
      text-align: right;
      margin-top: 7px;
      @include media-breakpoint-down(md) {
        text-align: center;
      }

      .btn {
        @include media-breakpoint-down(md) {
          float: initial;
          margin: 20px auto 0 auto;
          display: block;
          max-width: 240px;
        }
      }
    }
  }
}

.section_img_desc {
  overflow: hidden;
  @include media-breakpoint-down(md) {
    background-color: #f3ebe0;
  }

  .img_desc {
    float: left;
    padding-left: 150px;
    width: 100%;
    @include media-breakpoint-down(md) {
      padding-left: 0px;
      width: initial;
    }

    .bg_desc {
      background-color: $beige;
      display: flex;
      vertical-align: middle;
      align-items: center;
      @include media-breakpoint-down(md) {
        display: initial;
        vertical-align: middle;
        align-items: center;
      }

      img {
        float: left;
        margin-top: 5px;
        margin-bottom: 5px;
        margin-left: 5px;
        height: 150px;
        @include media-breakpoint-down(md) {
          float: initial;
          margin: 25px auto 0px auto;
        }
        @include media-breakpoint-down(sm) {
          height: initial;
        }
      }

      .desc {
        padding: 25px;
        height: 150px;
        @include media-breakpoint-down(md) {
          text-align: center;
        }
        @include media-breakpoint-down(sm) {
          padding: 15px 0;
          height: initial;
        }
      }
    }
  }
}

.section_galerie {
  background-color: #e2723f;
  margin: 0;
  padding: 0;

  .swiper-slide {
    height: 720px;
    @include media-breakpoint-down(sm) {
      height: 520px;
    }

    .item {
      width: 748px;
      height: 498px;
      margin: auto;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      @include media-breakpoint-down(sm) {
        width: 450px;
        height: 300px;
      }
    }

    .caption {
      color: #ffffff;
      font-family: "Acumin Pro Cond";
      font-size: 22px;
      font-weight: 700;
      line-height: initial;
      text-align: center;
      position: absolute;
      left: 0;
      right: 0;
      top: 640px;
      padding: 0 20px;
      @include media-breakpoint-down(sm) {
        top: 420px;
        font-size: 17px;
      }
    }
  }

  .swiper-button-next,
  .swiper-button-prev {
    background-color: #fff;
    width: 68px;
    height: 68px;
    border-radius: 50px;
    @include media-breakpoint-down(sm) {
      width: 55px;
      height: 55px;
    }

    &:after {
      content: "";
      height: 31px;
      width: 31px;
      background-image: url("../img/icon/arrow-left.svg");
    }
  }

  .swiper-button-prev {
    left: 10%;
    @include media-breakpoint-down(sm) {
      left: 4%;
    }

    &:after {
      content: "";
      transform: scaleX(-1);
    }
  }

  .swiper-button-next {
    right: 10%;
    @include media-breakpoint-down(sm) {
      right: 4%;
    }
  }
}

.section_chiffres {
  .block-img {
    height: 133px;
    margin: auto;
    display: table;

    img {
      max-height: 100px;
      max-width: 133px;
      margin: 20px 0;
    }
  }

  .chiffre {
    color: #e2723f;
    font-family: "Acumin Pro Cond";
    font-size: 75px;
    font-weight: 700;
    font-style: normal;
    letter-spacing: normal;
    line-height: normal;
    @include media-breakpoint-down(md) {
      font-size: 35px;
    }
  }

  .desc {
    color: #444444;
    font-family: "Inter";
    font-size: 16px;
    font-weight: 300;
    font-style: normal;
    letter-spacing: normal;
    line-height: 20px;
  }

  .sources {
    color: #444444;
    font-family: "Inter";
    font-size: 16px;
    font-weight: 300;
  }
}

.title-discover {
  color: #e2723f;
  font-family: "Acumin Pro Cond";
  font-size: 36px;
  font-weight: 700;
  text-align: left;
  position: relative;

  &:after {
    content: "";
    height: 34px;
    width: 107px;
    display: block;
    bottom: -50px;
    right: -50px;
    position: absolute;
    background-size: cover;
    background-image: url("../img/icon/arrow-o.png");
    @include media-breakpoint-down(md) {
      display: none;
    }
  }
}

.block-mea {
  overflow: hidden;
  position: relative;

  &:hover {
    .btn-arrow {
      background-image: url("../img/icon/arrow-left-w.svg");
      background-position: 17px center;
      transition: all ease 0.5s;
      border: 1px solid #4b0e14;
      background-color: #4b0e13;
      color: #ffffff !important;
      opacity: 1 !important;
    }
  }

  .mea {
    height: 450px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    padding: 15px;
    margin: 25px 25px 50px 25px;
    position: relative;
    overflow: hidden;
    display: block;
    transition: all ease 0.5s;
    @include media-breakpoint-down(md) {
      height: 200px;
    }

    &:hover {
      transform: scale(1.05);
      transition: all ease 0.5s;
    }
  }

  .block-bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    display: block;
    padding: 0 30px 20px 30px;
    transform: scale(1);

    h3 {
      width: 100%;
      color: #ffffff;
      font-family: "Acumin Pro Cond";
      font-size: 28px;
      font-weight: 700;
      font-style: normal;
      letter-spacing: normal;
      line-height: 34px;
      text-align: left;

      &.txt-b {
        color: #000000;
      }
    }

    .btn {
      float: right;
      display: block;
      position: absolute;
      right: 12px;
      bottom: -6px;
    }
  }

  &.mea2 {
    margin-top: 60px;
    @include media-breakpoint-down(md) {
      margin-top: initial;
    }
  }
}

.ressources {
  .filters {
    .label {
      color: #e2723f;
      font-family: "Inter";
      font-size: 16px;
      font-weight: 700;
      line-height: 34px;
      text-align: left;
    }

    select {
      background-repeat: no-repeat;
      background-size: 14px;
      background-position: calc(100% - 10px) center;
      padding: 0px 40px 0px 10px;
      background-color: initial !important;
      border: none;
      font-size: 18px;
    }
  }

  .list-ressources {
    background-color: #fff;

    .ressource {
      border-bottom: 1px solid #e9e9e9;

      .img {
        float: left;
        width: 100px;
        height: 80px;
        margin: auto;
        vertical-align: middle;
        align-items: center;
        display: flex;

        img {
          margin: auto;
          max-height: 80px;
          max-width: 100px;
          box-shadow: 0 7px 14px #e6e6e6;
        }
      }

      .detail {
        float: left;

        h3 {
          color: #e2723f;
          font-family: "Acumin Pro Cond";
          font-size: 28px;
          font-weight: 700;
          line-height: 34px;
          text-align: left;
          @include media-breakpoint-down(md) {
            font-size: 18px;
            line-height: 24px;
          }
        }

        .date-res {
          color: #444444;
          font-family: "Inter";
          font-size: 16px;
          font-weight: 300;
          line-height: 24px;
          text-align: left;
          @include media-breakpoint-down(md) {
            font-size: 14px;
            line-height: 18px;
          }
        }
      }
    }

    .btn {
      color: #e2723f;
      font-family: "Acumin Pro Cond";
      font-size: 20px;
      font-weight: 400;
      line-height: 24px;
      text-align: center;
      @include media-breakpoint-down(md) {
        border: 1px solid #eee;
        padding: 5px 18px 2px 18px;
        font-size: 16px;
      }
    }
  }
}

#page-solutions-archive {
  .block-mea {
    background: #fff;

    .mea {
      height: 450px;
      @include media-breakpoint-down(lg) {
        height: 330px;
        max-width: 420px;
        margin: auto;
      }
    }
  }
}

.loading {
  display: none;

  img {
    height: 20px;
    width: 20px;
  }
}

.discover {
  .block-mea {
    .mea {
      background-size: cover;
      margin: 0;
    }
  }
}
