/*
|--------------------
|       404
|--------------------
*/

#page-404 {
  background-size: cover;

  .section-content {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
    color: $white;
    text-align: center;

    .item-title {
      font-size: 40px;
      font-weight: 500;
      line-height: 1;
        text-align: center;
    }

    .item-text {
      font-size: 18px;

      a {
        color: white;
        text-decoration: underline!important;
      }
    }
  }
}
