/*
|--------------------------
|      DEFAULT & RESET
|--------------------------
*/
html {
  box-sizing: border-box;
  -ms-overflow-style: scrollbar;
}

* {
  margin: 0;
  padding: 0;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}
.o-h {
  overflow: hidden;
}
body {
  font-family: $font-family-default;
  color: $default-color;
  font-size: $default-font-size;
  line-height: $default-line-height;
  font-weight: $default-font-weight;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  overflow-x: hidden;
  background-color: #f3ebe0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  @each $property, $value in $default-titles {
    #{$property}: $value;
  }
}

h1 {
  color: #ffffff;
  font-family: "Acumin Pro Cond";
  font-size: 75px;
  line-height: 75px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  position: relative;
  z-index: 2;

  @include media-breakpoint-down(md) {
    font-size: 55px;
    line-height: 55px;
  }
  @include media-breakpoint-down(sm) {
    font-size: 45px;
    line-height: 45px;
  }
}

.h2-orange {
  color: #e2723f;
  font-family: "Acumin Pro Cond";
  font-size: 28px;
  font-weight: 700;
}

.subtitle {
  color: #ffffff;
  font-family: "Inter";
  font-size: 20px;
  font-weight: 300;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  position: relative;
  z-index: 2;
}

.site-container {
  overflow: hidden;
}

button,
input,
textarea {
  &:focus {
    outline: none !important;
    box-shadow: none !important;
  }
}

button {
  -webkit-appearance: none;
  padding: 0;
  background-color: transparent;
  border: none;
}

img {
  max-width: inherit;
}
.aligncenter {
  margin: auto;
}

a,
img,
span,
button {
  display: inline-block;
}

a {
  color: $default-link-color;
  text-decoration: none;

  &:hover {
    color: $default-link-color;
  }
}

.flex {
  display: flex;
  vertical-align: middle;
  align-items: center;
}

.txt-c {
  text-align: center;
}

a,
img,
span,
button {
  display: inline-block;
}

a {
  color: $default-link-color;
  text-decoration: none !important;

  &:hover {
    color: $default-link-color;
  }
}

.bdr-b {
  border: 1px solid #000;
}

.m-a {
  margin: auto;
}

.o-h {
  overflow: hidden;
}

.mg-c {
  margin: auto;
  display: table;
}

.txt-c {
  text-align: center;
}

.txt-r {
  text-align: right;
  @include media-breakpoint-down(md) {
    text-align: left;
  }
}

@include media-breakpoint-down(md) {
  .m-txt-c {
    text-align: center;
  }
}

.dis-desk {
  display: block;
  @include media-breakpoint-down(md) {
    display: none;
  }
}

.dis-mob {
  display: none;
  @include media-breakpoint-down(md) {
    display: block;
  }
}

.z1 {
  z-index: 1;
  position: relative;
}

ul {
  margin: 0;
  padding: 0;

  li {
    list-style-type: none;
  }
}

.center {
  margin: auto;
  display: block;
}

.rel {
  position: relative;
}

.clearfix {
  clear: both;
  width: 100%;
}
