/*
|--------------------
|      NEWS
|--------------------
*/

.experts {
    @include media-breakpoint-down(md) {
        text-align: center;
    }
    .infos {
        position: relative;
        min-height: 180px;
        @include media-breakpoint-down(md) {
            margin-top: 10px;
            min-height: initial;
        }
        .elem-infos {
            position: absolute;
            bottom: 0;
            @include media-breakpoint-down(md) {
                margin-top: 35px;
                position: relative;
            }
            h3 {
                text-transform: uppercase;
                color: #e2723f;
                font-family: "Acumin Pro Cond";
                font-size: 38px;
                line-height: initial;
                font-weight: 700;
                text-align: left;
                position: relative;
                @include media-breakpoint-down(md) {
                    text-align: center;
                }
            }
            .resp {
                color: #141414;
                font-family: "Acumin Pro Cond";
            }
        }
    }
    .img-expert {
        max-height: 180px;
        @include media-breakpoint-down(md) {
            margin: auto;
            display: block;
        }
    }
    .img-expert-mob {
        width: 100%;
        display: block;
        padding: 0;
        margin: 0;
    }

    .expert-elem {
        .couv {
            background-size: cover;
            background-position: center;
            width: 100%;
            height: 360px;
            @include media-breakpoint-down(md) {
                height: initial;
            }
            .block-hover {
                background: #e2723f;
                width: 100%;
                height: 360px;
                opacity: 0;
                transition: all ease 0.3s;
                @include media-breakpoint-down(md) {
                    height: initial;
                    display: block;
                    opacity: 1;
                }
                .title {
                    color: white;
                    font-family: "Acumin Pro Cond";
                    text-align: center;
                    font-size: 30px;
                    line-height: 27px;
                    padding-top: 15px;
                }
                .keys {
                    display: flex;
                    vertical-align: middle;
                    align-items: center;
                    height: 220px;
                    @include media-breakpoint-down(md) {
                        display: initial;
                        height: initial;
                    }
                    .colonne {
                        color: white;
                        font-family: "Acumin Pro Cond";
                        text-align: center;
                        font-size: 19px;
                        line-height: 23px;
                        text-transform: uppercase;
                        height: 140px;
                        @include media-breakpoint-down(lg) {
                            height: inherit;
                        }
                        span {
                            display: block;
                            position: relative;
                            margin-bottom: 15px;
                            &:before {
                                content: "";
                                width: 100%;
                                height: 1px;
                                display: block;
                                background-color: white;
                                position: absolute;
                                bottom: -4px;
                            }
                        }
                    }
                }
                .link-video {
                    color: white;
                    border: 2px solid white;
                    padding: 6px 26px 4px 18px;
                    margin: auto;
                    display: table;
                    font-size: 19px;
                    font-family: "Acumin Pro Cond";
                    position: absolute;
                    bottom: 18px;
                    left: 0;
                    right: 0;
                    cursor: pointer;
                    transition: all ease 0.3s;
                    @include media-breakpoint-down(md) {
                        margin: 0px auto;
                        display: table;
                        position: relative;
                    }
                    &:hover {
                        opacity: 0.6;
                        transition: all ease 0.3s;
                    }
                    .image {
                        height: 32px;
                        width: 32px;
                        background-color: white;
                        border-radius: 50%;
                        margin-right: 10px;
                        float: left;
                        img {
                            height: 15px;
                            width: 15px;
                            margin-left: 9px;
                        }
                    }
                    span {
                        padding-top: 1px;
                    }
                }
            }
        }
        &:hover {
            .block-hover {
                opacity: 1;
                transition: all ease 0.3s;
            }
        }
    }
}
.videomodal {
    .closeModal {
        height: 30px;
        width: 30px;
        display: block;
        border-radius: 50%;
        position: absolute;
        right: 10px;
        top: 10px;
        cursor: pointer;
        z-index: 1;
        &:before {
            content: "";
            height: 30px;
            width: 3px;
            display: block;
            background-color: $white;
            transform: rotate(-45deg);
            position: absolute;
            top: 0;
            right: 13px;
        }
        &:after {
            content: "";
            height: 30px;
            width: 3px;
            display: block;
            background-color: $white;
            transform: rotate(45deg);
            position: absolute;
            top: 0;
            right: 13px;
        }
    }
    .videoytexpert {
        width: 100%;
        padding: 0;
        margin: 0;
        box-shadow: none;
        background: black;
        border: none;
        border-radius: none;
    }
    .modal-body {
        padding: 0;
    }
    .modal-dialog {
        width: 100%;
        max-width: 1098px;
        margin: 30px auto;
    }
    .player {
        overflow: hidden;
        iframe {
            width: 100%;
            max-height: 617px;
            transform: scale(1.04);
        }
    }
}
