/*
|--------------------
|     PAGE HOME
|--------------------
*/

#page-home {
  .banner {
    min-height: 80vh;
    @include media-breakpoint-down(md) {
      min-height: initial;
    }

    &:after {
      content: "";
      background-color: #000;
      opacity: 0.2;
      position: absolute;
      bottom: 0;
      top: 0;
      left: 0;
      right: 0;
      height: auto;
      z-index: 1;
    }
  }

  h2 {
    position: absolute;
    top: 10px;
    background-color: #fff;
    padding: 30px 25px;
    min-width: 300px;
    z-index: 1;
    margin-left: 40px;
    color: #e2723f;
    font-family: "Inter";
    font-size: 16px;
    font-weight: 700;
    font-style: normal;
    letter-spacing: normal;
    line-height: 34px;
    text-align: left;
    @include media-breakpoint-down(md) {
      top: 0;
      margin: 0;
      margin-bottom: 20px;
      min-width: initial;
      position: relative;
      text-align: center;
      font-size: 20px;
    }
  }

  .section1 {
    position: relative;

    .block-mea {
      &.mea1 {
        margin-top: 75px !important;
        @include media-breakpoint-down(md) {
          margin-top: initial !important;
        }
      }

      &.mea2 {
        margin-top: 180px !important;
        @include media-breakpoint-down(md) {
          margin-top: initial !important;
        }
      }

      &.mea3 {
        margin-top: 10px !important;
        @include media-breakpoint-down(md) {
          margin-top: initial !important;
        }
      }

      &:hover {
        .btn-arrow {
          background-image: url("../img/icon/arrow-left-w.svg");
          background-position: 17px center;
          transition: all ease 0.5s;
          border: 1px solid #4b0e14;
          background-color: #4b0e13;
          color: #ffffff !important;
          opacity: 1 !important;
        }
      }
    }

    .block-bottom {
      .btn {
        float: right;
        display: block;
        position: absolute;
        right: 12px;
        bottom: -6px;
      }
    }
  }

  .section2 {
    position: relative;
    color: #ffffff;

    h2 {
      margin-top: -75px;
      @include media-breakpoint-down(md) {
        margin-top: initial !important;
      }
    }

    .bg {
      background-size: cover;
      background-position: center;
      width: 100%;
    }

    h3 {
      color: #ffffff;
      font-family: "Acumin Pro Cond";
      font-size: 28px;
      font-weight: 700;
      line-height: initial;
      text-align: left;
    }

    p {
      color: #ffffff;
      font-family: "Inter";
      font-size: 16px;
      font-weight: 300;
      text-align: left;
    }

    .btn {
      color: #e2723f;
    }
  }

  .section3 {
    position: relative;

    h2 {
      margin-top: -75px;
      @include media-breakpoint-down(md) {
        margin-top: initial !important;
      }
    }

    .item-s3 {
      .bg {
        background-size: cover;
        background-position: center;
        width: 100%;
        min-height: 450px;
        @include media-breakpoint-down(md) {
          min-height: 200px;
        }
      }

      h3 {
        color: #e2723f;
        font-family: "Acumin Pro Cond";
        font-size: 28px;
        font-weight: 700;
        line-height: 34px;
        text-align: left;
      }

      .desc {
        p {
          color: #444444;
          font-family: "Inter";
          font-size: 16px;
          line-height: 24px;
          font-weight: 300;
          text-align: left;
        }
      }

      .btn {
        color: #e2723f;
      }
    }
  }

  .block-mea {
    .mea {
      background-size: cover;
      margin: 0;
    }
  }
}
