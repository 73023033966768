/*
|--------------------
|      FOOTER
|--------------------
*/
#footer {
  padding: 40px 0;
  background-color: $red;
  color: #ffffff;
  font-family: "Inter";
  font-size: 16px;
  font-weight: 300;
  font-style: normal;
  letter-spacing: normal;
  line-height: 26px;
  text-align: left;
  @include media-breakpoint-down(md) {
    padding: 40px 0 15px 0;
    text-align: center;
  }

  .col1 {
    color: #ffffff;
    font-family: "Acumin Pro Cond";
    font-size: 25px;
    line-height: 30px;
    font-weight: 700;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    @include media-breakpoint-down(md) {
      text-align: center;
    }
  }

  .logo-ft {
    margin-top: -60px;
  }

  .link-footer {
    a {
      color: #ffffff;
      font-family: "Inter";
      font-size: 16px;
      font-weight: 300;
      font-style: normal;
      letter-spacing: normal;
      line-height: 30px;
      text-align: left;
      padding: 3px 0;

      &:hover {
        text-decoration: underline !important;
      }
    }

    ul {
      margin: 0;
      padding: 0;

      li {
        list-style-type: none;
      }
    }
  }

  .rs {
    position: relative;

    &:before {
      content: "";
      border-top: 1px solid #ffffff;
      opacity: 0.2;
      width: 100%;
    }

    a {
      padding-left: 50px;
      @include media-breakpoint-down(md) {
        padding-left: 20px;
        padding-right: 20px;
      }
    }
  }

  .txt-r {
    @include media-breakpoint-down(md) {
      text-align: center;
    }
  }
}
